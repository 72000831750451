<template>
  <div class="mobile-menu-container">
    <!-- mobile-menu-light -->
    <div class="mobile-menu-wrapper">
      <!-- <form action="#" method="get" class="mobile-search" @submit.prevent="submitSearchForm">
                <label for="mobile-search" class="sr-only">Search</label>
                <input type="search" class="form-control" name="mobile-search" id="mobile-search" v-model="searchTerm"
                    placeholder="Search product ..." required />
                <button class="btn btn-primary" type="submit">
                    <i class="icon-search"></i>
                </button>
            </form> -->

      <div class="tab-content">
        <div class="tab-pane fade show active" id="mobile-nav">
          <nav class="mobile-nav">
            <ul class="mobile-menu">
              <li>
                <router-link to="/" exact class="sf-with-ul">
                  {{ $t("home") }}
                </router-link>
              </li>
              <li v-for="(item, i) in menu" :key="i">
                <router-link
                  :to="'/' + item.url_path + '/'"
                  class="sf-with-ul text-white"
                  @click.native.prevent="toggleSubmenu(item)"
                  aria-haspopup="true"
                  :aria-expanded="item.isOpen ? 'true' : 'false'"
                >
                  {{ $t(item.name.toLowerCase()) }}
                  <span class="menu-chevron" v-if="item.children.length"></span>
                </router-link>
                <ul
                  class="sub-menu"
                  v-if="item.children && item.children.length"
                  v-show="item.isOpen"
                  role="menu"
                >
                  <li
                    v-for="(child, i) in item.children"
                    :key="i"
                    class="border-bottom border-dark"
                  >
                    <router-link
                      :to="'/' + child.url_path"
                      @click.native.stop="toggleSubmenu(child)"
                    >
                      {{ child.name }}
                      <span
                        class="submenu-arrow"
                        v-if="child.children.length"
                      ></span>
                    </router-link>
                    <ul
                      class="sub-sub-menu"
                      v-if="child.children && child.children.length"
                      v-show="child.isOpen"
                      role="menu"
                    >
                      <li
                        v-for="(grandchild, i) in child.children"
                        :key="i"
                        class="border-bottom"
                      >
                        <router-link
                          :to="'/' + grandchild.url_path"
                          class="text-dark"
                          :class="{
                            'font-weight-bold': isActive(grandchild.url_path),
                          }"
                        >
                          {{ grandchild.name }}
                        </router-link>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
              <div v-html="headerTopLinks.content"></div>
              <li>
                <router-link to="/" exact class="sf-with-ul" @click.native="toggleChat">
                  Support
                </router-link>
              </li>
            </ul>
          </nav>
        </div>
        <!-- <div class="tab-pane fade" id="mobile-categories">
          <nav class="mobile-cats-nav">
            <ul class="mobile-cats-menu">
              <li class="item-cats-lead">
                <router-link :to="'/shop/3cols?category=electronics'"
                  >Electronics</router-link
                >
              </li>
              <li class="item-cats-lead">
                <router-link :to="'/shop/3cols?category=gift-idea'"
                  >Gift Ideas</router-link
                >
              </li>
              <li>
                <router-link :to="'/shop/3cols?category=beds'"
                  >Beds</router-link
                >
              </li>
              <li>
                <router-link :to="'/shop/3cols?category=lighting'"
                  >Lighting</router-link
                >
              </li>
              <li>
                <router-link
                  :to="'/shop/3cols?category=sofas-and-sleeper-sofas'"
                  >Sofas & Sleeper sofas</router-link
                >
              </li>
              <li>
                <router-link :to="'/shop/3cols?category=storage'"
                  >Storage</router-link
                >
              </li>
              <li>
                <router-link :to="'/shop/3cols?category=armchairs-and-chaises'"
                  >Armchairs & Chaises</router-link
                >
              </li>
              <li>
                <router-link :to="'/shop/3cols?category=decoration'"
                  >Decoration</router-link
                >
              </li>
              <li>
                <router-link :to="'/shop/3cols?category=kitchen-cabinets'"
                  >Kitchen Cabinets</router-link
                >
              </li>
              <li>
                <router-link :to="'/shop/3cols?category=coffee-and-tables'"
                  >Coffee & Tables</router-link
                >
              </li>
              <li>
                <router-link :to="'/shop/3cols?category=furniture'"
                  >Outdoor Furniture</router-link
                >
              </li>
            </ul>
          </nav>
        </div> -->
      </div>
    </div>
  </div>
</template>
<script>
import { mobileMenu } from "@/esf_weert_mobilesupplies/utilities/common.js";

export default {
  props: {
    isChatOpen: {
      type: Boolean,
      required: true
    }
  },

  data: function () {
    return {
      tabsData: [
        {
          id: "mobile-nav",
          title: "menu",
          active: true,
        },
        // {
        //   id: "mobile-categories",
        //   title: "category",
        // },
      ],
      searchTerm: "",
    };
  },
  computed: {
    menu() {
      return this.$store.getters["menu/getMenu"];
    },
    headerTopLinks() {
      return this.$store.getters[`cmsBlock/getCmsBlockByIdentifier`](
        "header-top-links"
      );
    },
  },
  mounted: function () {
    mobileMenu();
  },
  methods: {
    hideMobileMenu: function () {
      this.$nextTick(() => {
        const body = document.querySelector("body");
        if (body) {
          body.classList.remove("mmenu-activemmenu-active");
        }
      });
    },
    isActive(path) {
      return this.$route.path === "/" + path;
    },
    submitSearchForm: function () {
      this.hideMobileMenu();
      this.$router.push({
        path: "/shop/3cols",
        query: {
          searchTerm: this.searchTerm,
        },
      });
    },
    toggleChat() {
      this.$emit('update:isChatOpen', !this.isChatOpen);
    }
  },
};
</script>
